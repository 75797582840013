import React from 'react';
import {Grid} from "@mui/material";
import PopUpInfo from "./PopUpInfo";
import "./styling/CardItem.css";

function CardItem(props) {

    return (
        <Grid item>
            <div className='cards__item'>
                <div className='cards__item__link'>
                    <div className='cards__item__info'>
                        <h5 className='cards__item__text'>{props.name}</h5>
                        <ul className='cards__item__ul'>
                            <p>Profilnummer: {props.identifier}</p>
                            <p>Herausgeber: {props.url}</p>
                            <p>Team: {props.profilTeam}</p>
                            <p>Anwendungsgebiet: {props.areaOfApplication}</p>
                            <PopUpInfo
                                id={props.id}
                                key={props.guid}
                                text={props.name}
                                label={props.lifeCyclePhase}
                                Profilnummer={props.identifier}
                                Beschreibung={props.definition}
                                Herausgeber={props.url}
                                Team={props.profilTeam}
                                Anwendungsgebiet={props.areaOfApplication}
                                Sprache={props.sprache}
                                ProfilVersion={props.version}
                            />
                        </ul>
                    </div>
                    <div className='cards__item__labels'>
                        <Grid container justifyContent="flex-start">
                            {props.lifeCyclePhase ? <button className='cards__item__label__cycle'>
                                {props.lifeCyclePhase}
                            </button> : <div/>}
                            {props.sprache ? <button className='cards__item__label__language'>
                                {props.sprache}
                            </button> : <div/>}
                        </Grid>
                    </div>
                </div>
            </div>
        </Grid>
    );
}

export default CardItem;
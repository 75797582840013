import React, {useState} from 'react';

// Token (aktuell über Cookies)
export const TokenContext = React.createContext('');
// Token-Abluaf (aktuell über Cookies)
export const TokenTimerContext = React.createContext(0);
// Tokenstatus (aktuell über Cookies)
export const StatusContext = React.createContext('');
// Benutzername
export const UsernameContext = React.createContext('');
// Passwort
export const PasswordContext = React.createContext('');
// Unbearbeitete Datenmenge aus JSON-Datei (bzw. von der API)
export const ProfilesRawContext = React.createContext([]);
// Filterung aktiv - ja oder nein
export const ProfilesFilterBoolContext = React.createContext(false);
// Profile von API geladen - ja oder nein
export const ProfilesLoadBoolContext = React.createContext(false);
// Gefilterte Datenmenge aus JSON
export const ProfilesFilteredContext = React.createContext([]);
// Anzahl dargestellter BIM-Profil-Kacheln
export const ProfilesCountContext = React.createContext(0);

export const ChosenItemContext = React.createContext([]);
export const ChosenOpenContext = React.createContext(false);

export const PropChosenBoolContext = React.createContext([]);

const Store = ({children}) => {

    const [token, setToken] = useState('');
    const [tokenTimer, setTokenTimer] = useState(0);
    const [status, setStatus] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [profilesRaw, setProfilesRaw] = useState([]);
    const [profilesFilterBool, setProfilesFilterBool] = useState(false);
    const [profilesLoadBool, setProfilesLoadBool] = useState(false);
    const [profilesFiltered, setProfilesFiltered] = useState([]);
    const [profilesCount, setProfilesCount] = useState(0);
    const [propChosenBool, setPropChosenBool] = useState([]);
    const [chosenItem, setChosenItem] = useState([]);
    const [chosenOpen, setChosenOpen] = useState(false);

    return (
        <TokenContext.Provider value={[token, setToken]}>
            <TokenTimerContext.Provider value={[tokenTimer, setTokenTimer]}>
                <StatusContext.Provider value={[status, setStatus]}>
                    <UsernameContext.Provider value={[username, setUsername]}>
                        <PasswordContext.Provider value={[password, setPassword]}>
                            <ProfilesRawContext.Provider value={[profilesRaw, setProfilesRaw]}>
                                <ProfilesFilterBoolContext.Provider value={[profilesFilterBool, setProfilesFilterBool]}>
                                    <ProfilesLoadBoolContext.Provider value={[profilesLoadBool, setProfilesLoadBool]}>
                                        <ProfilesFilteredContext.Provider value={[profilesFiltered, setProfilesFiltered]}>
                                            <ProfilesCountContext.Provider value={[profilesCount, setProfilesCount]}>
                                                <PropChosenBoolContext.Provider value={[propChosenBool, setPropChosenBool]}>
                                                    <ChosenItemContext.Provider value={[chosenItem, setChosenItem]}>
                                                        <ChosenOpenContext.Provider value={[chosenOpen, setChosenOpen]}>
                                                            {children}
                                                        </ChosenOpenContext.Provider>
                                                    </ChosenItemContext.Provider>
                                                </PropChosenBoolContext.Provider>
                                            </ProfilesCountContext.Provider>
                                        </ProfilesFilteredContext.Provider>
                                    </ProfilesLoadBoolContext.Provider>
                                </ProfilesFilterBoolContext.Provider>
                            </ProfilesRawContext.Provider>
                        </PasswordContext.Provider>
                    </UsernameContext.Provider>
                </StatusContext.Provider>
            </TokenTimerContext.Provider>
        </TokenContext.Provider>
    )
}
export default Store;
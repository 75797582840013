import React, {useContext, useState} from 'react';
import TextField from "@mui/material/TextField";
import {Typography} from "@mui/material";
import {ProfilesCountContext, ProfilesRawContext, ProfilesFilteredContext, ProfilesFilterBoolContext} from "./Store";
import Grid from "@mui/material/Grid";
import "./styling/FilterItem.css";

function FilterItem(cycleItems, sprache) {

    const [profilesRaw] = useContext(ProfilesRawContext);
    const [, setProfilesFiltered] = useContext(ProfilesFilteredContext);
    const [, setProfilesFilterBool] = useContext(ProfilesFilterBoolContext);
    const [profilesCount] = useContext(ProfilesCountContext);

    const [search, setSearch] = useState("");
    const [label, setLabel] = useState("");

    // Zwischenarrays

    let arr_S = []
    let arr_L = []
    let arr_SL = []

    // Filteroperationen

    function filterArray(S, L) {

        // S = Sucheingabe
        // L = Label-Auswahl

        let r = profilesRaw;

        if (S && !L) {

            // Rückgabe mit Sucheingabe aber ohne Cycle-Auswahl

            try {
                for (let i = 0; i < r.length; i++) {
                    if (r[i].name && r[i].name.toLowerCase().includes(S.toLowerCase())) {
                        arr_S.push(r[i])
                    } else if (r[i].identifier && r[i].identifier.toLowerCase().includes(S.toLowerCase())) {
                        arr_S.push(r[i])
                    } else if (r[i].url && r[i].url.toLowerCase().includes(S.toLowerCase())) {
                        arr_S.push(r[i])
                    } else if (r[i].profilTeam && r[i].profilTeam.toLowerCase().includes(S.toLowerCase())) {
                        arr_S.push(r[i])
                    } else if (r[i].areaOfApplication && r[i].areaOfApplication.toLowerCase().includes(S.toLowerCase())) {
                        arr_S.push(r[i])
                    } else {
                        arr_S.push()
                    }
                    setProfilesFiltered(arr_S)
                }
            } catch (e) {
                console.log(e)
            }

        } else if (!S && L) {

            // Rückgabe mit Cycle-Auswahl aber ohne Sucheingabe

            try {
                for (let i = 0; i < r.length; i++) {
                    if (r[i].lifeCyclePhase === (L)) {
                        arr_L.push(r[i])
                    }
                    setProfilesFiltered(arr_L)
                }
            } catch (e) {
                console.log(e)
            }

        } else if (S && L) {

            // Rückgabe mit Sucheingabe und Cycle-Auswahl

            try {
                for (let i = 0; i < r.length; i++) {

                    if (r[i].name &&
                        r[i].name.toLowerCase().includes(S.toLowerCase()) &&
                        r[i].lifeCyclePhase === (L)
                    ) {
                        arr_SL.push(r[i])

                    } else if (r[i].identifier &&
                        r[i].identifier.toLowerCase().includes(S.toLowerCase()) &&
                        r[i].lifeCyclePhase === (L)
                    ) {
                        arr_SL.push(r[i])

                    } else if (r[i].url &&
                        r[i].url.toLowerCase().includes(S.toLowerCase()) &&
                        r[i].lifeCyclePhase === (L)
                    ) {
                        arr_SL.push(r[i])

                    } else if (r[i].profilTeam &&
                        r[i].profilTeam.toLowerCase().includes(S.toLowerCase()) &&
                        r[i].lifeCyclePhase === (L)
                    ) {
                        arr_SL.push(r[i])

                    } else if (r[i].areaOfApplication &&
                        r[i].areaOfApplication.toLowerCase().includes(S.toLowerCase()) &&
                        r[i].lifeCyclePhase === (L)
                    ) {
                        arr_SL.push(r[i])
                    }

                    setProfilesFiltered(arr_SL)
                }
            } catch (e) {
                console.log(e)
            }

        } else if (!S && !L) {

            // Rückgabe ohne Suchfeldeingabe oder Cacle-Auswahl

            setProfilesFiltered(profilesRaw)
        }
    }

    // Filtereingaben und Rückgabewerte zurücksetzen

    function Reset() {
        setProfilesFilterBool(false);
        setProfilesFiltered([]);
        setSearch("");
        setLabel("");
        document.getElementById("NameForm").value = "";
    }

    let g = cycleItems.cycleItems
    //let h = sprache.sprache

    // Lebenszyklus-Anzeige abhängig von den dargestellten Kacheln

    const ShowCycleItems = () => (
        <div className='filter__showCycleItems'>
            {g.map((value) => {
                return (
                    <React.Fragment key={value}>
                        <button className='filter__cycleItem' onClick={() => {
                            setLabel(value);
                            filterArray(search, value);
                            setProfilesFilterBool(true)
                        }}>
                            {value}
                        </button>
                    </React.Fragment>
                )
            })}
        </div>
    )
/*
    const ShowSpracheItems = () => (
        <div className='filter__showCycleItems'>
            {h.map((value) => {
                return (
                    <React.Fragment key={value}>
                        <button className='filter__cycleItem' onClick={() => {
                            console.log(value)
                        }}>
                            {value}
                        </button>
                    </React.Fragment>
                )
            })}
        </div>
    )
*/
    const countMessage = () => {
        if (profilesCount > 1 || profilesCount === 0) {
            return <Typography>Es werden <b>{profilesCount}</b> BIM-Profile dargestellt.</Typography>
        } else {
            return <Typography>Es wird <b>{profilesCount}</b> BIM-Profil dargestellt.</Typography>
        }
    }

    return (
        <div className='filter__box'>
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={5}>
                    <div className='filter__text'>
                        <TextField
                            id="NameForm"
                            label='Suche'
                            placeholder=''
                            fullWidth
                            onChange={e => setSearch(e.target.value)}
                        />
                    </div>
                    <p className='filter__tip'>z.B.: Glasreinigung, CAFM-Ring, GEFMA</p>
                    <button className='filter__button' onClick={() => {
                        filterArray(search, label);
                        setProfilesFilterBool(true)
                    }}>Suchen
                    </button>
                    <button className='filter__button' onClick={() => {
                        Reset()
                    }}>Zurücksetzen
                    </button>
                </Grid>
                <Grid item xs={7}>
                    <div className='filter__right'>
                        <ShowCycleItems/>
                    </div>
                    <div className='filter__right'>
                        {countMessage()}
                    </div>
                </Grid>
            </Grid>
        </div>

    );
}

export default FilterItem;
import Store from './components/Store';
import Cards from './components/Cards';

function App() {
  return (
      <>
        <Store>
            <Cards/>
        </Store>
      </>
  );
}

export default App;

import React, {useContext} from "react";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {ChosenItemContext, ChosenOpenContext} from "./Store";

const dataBoxStyling2 = {
    p: 1,
    m: 1,
    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
    border: '1px solid',
    borderColor: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
    borderRadius: 2,
    fontSize: '0.875rem',
    fontWeight: '700',
    width: 'auto'
}

const tableStyling = {
    p: 1,
    m: 1,
    backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
    color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
    border: '1px solid',
    borderColor: (theme) =>
        theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
    borderRadius: 2,
    fontSize: '0.875rem',
    fontWeight: '700',
    maxHeight: '72.3vh'
}

export default function Detail() {

    const [chosenItem] = useContext(ChosenItemContext);
    const [chosenOpen] = useContext(ChosenOpenContext);

    if (chosenOpen) {
        if (chosenItem.parentClass) {
            return (
                <>
                    <Box sx={dataBoxStyling2}>

                        <TableContainer >
                            <Table sx={{ tableStyling }} >
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Typ</TableCell>
                                        <TableCell>Klasse</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        {chosenItem.nameEn ?
                                            <TableCell>{chosenItem.nameDe} / {chosenItem.nameEn}</TableCell> :
                                            <TableCell>{chosenItem.nameDe}</TableCell>
                                        }
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Identifikator</TableCell>
                                        <TableCell>{chosenItem.identifier}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                </>
            )
        } else {
            return (
                <>
                    <Box sx={dataBoxStyling2}>

                        <TableContainer >
                            <Table sx={{ tableStyling }} >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Typ</TableCell>
                                        <TableCell>Merkmal</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        {chosenItem.nameEn ?
                                            <TableCell>{chosenItem.nameDe} / {chosenItem.nameEn}</TableCell> :
                                            <TableCell>{chosenItem.nameDe}</TableCell>
                                        }
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>System-Name</TableCell>
                                        <TableCell>{chosenItem.systemName}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Definition</TableCell>
                                        {chosenItem.definitionEn ?
                                            <TableCell>{chosenItem.definitionDe} / {chosenItem.definitionEn}</TableCell> :
                                            <TableCell>{chosenItem.definitionDe}</TableCell>
                                        }
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                </>
            )
        }

    }
}
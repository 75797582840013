import React, {useState, useContext, useEffect} from 'react';
import {Box, DialogTitle, DialogContent, DialogActions, Dialog, Grid} from "@mui/material";
import './styling/Popup.css';
import TreeComponent from "./TreeComponent";
import {Client} from "../services/Client.ts";
import Detail from "./Detail";
import {ChosenItemContext, ChosenOpenContext} from "./Store";

export default function PopUpInfo(props) {

    const [, setChosenItem] = useContext(ChosenItemContext);
    const [, setChosenOpen] = useContext(ChosenOpenContext);
    const [open, setOpen] = useState(false);
    const [prof, setProf] = useState();

    function ExportButton() {

        return (
            <ul className='cards__item__export'>
                <>
                    <p className='cards__item__pop__p'>Export-Formate:</p>
                    <Grid container rowSpacing={0.5} columnSpacing={{xs: 1, sm: 2, md: 3}}>
                        <Grid item xs={6}>
                            <button className='pop__button__export__off'>ArchiCad</button>
                        </Grid>
                        <Grid item xs={6}>
                            <button className='pop__button__export__off'>Revit</button>
                        </Grid>
                        <Grid item xs={6}>
                            <button className='pop__button__export__off'>Prüfregel</button>
                        </Grid>
                        <Grid item xs={6}>
                            <button className='pop__button__export__off'>Excel</button>
                        </Grid>
                        <Grid item xs={6}>
                            <button className='pop__button__export__off'>IFC-XML</button>
                        </Grid>
                        <Grid item xs={6}>
                            <button className='pop__button__export__off'>BSDD</button>
                        </Grid>
                    </Grid>
                </>
            </ul>
        )
    }

    // API-Request zu genau diesem Profil
    async function getProfileById_Client(id) {

        try {
            let client = new Client();
            await client.getBimProfile(id)
                .then(data => {
                    setProf(data)
                })
        } catch (err) {
            console.log(err)
        }
    }

    // Styling
    const outboxStyling = {
        display: 'flex',
        flexWrap: 'no-wrap',
        p: 1,
        m: 1,
        bgcolor: 'background.paper',
        borderRadius: 1,
        flexDirection: 'row',
        height: '74vh',
    }

    const boxStyling = {
        display: 'flex',
        flexWrap: 'no-wrap',
        p: 1,
        m: 1,
        bgcolor: 'background.paper',
        width: '30%',
        borderRadius: 1,
        flexDirection: 'column'
    }

    const dataBoxStyling = {
        p: 1,
        m: 1,
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        width: 'auto',
        height: '50vh'
    }

    const exportBoxStyling = {
        p: 1,
        m: 1,
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
    }

    const tableBoxStyling = {
        p: 1,
        m: 1,
        backgroundColor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : 'grey.100'),
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
            theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        maxHeight: '72.3vh'
    }

    return (
        <>
            <div className='pop__container'>
                <button className='pop__button' onClick={() => {
                    setOpen(true);
                    getProfileById_Client(props.id).then(r => console.log())
                }}>+
                </button>
            </div>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    setChosenItem([]);
                    setChosenOpen(false);
                }}
                aria-labelledby="responsive-dialog-title"
                maxWidth={'xl'}
                fullWidth={true}
            >
                <DialogTitle id="responsive-dialog-title" className='pop__title'>
                    {props.text}
                </DialogTitle>
                <DialogContent>
                    <Box sx={outboxStyling}>
                        <Box sx={boxStyling}>
                            <Box sx={dataBoxStyling}>
                                <ul className='pop__ul'>
                                    <p className='pop__ul__name'>Profilnummer:</p>
                                    <p>{props.Profilnummer}</p>
                                    <p className='pop__ul__name'>Beschreibung:</p>
                                    <p>{props.Beschreibung}</p>
                                    <p className='pop__ul__name'>Herausgeber:</p>
                                    <p>{props.Herausgeber}</p>
                                    <p className='pop__ul__name'>Team:</p>
                                    <p>{props.Team}</p>
                                    <p className='pop__ul__name'>Anwendungsgebiet:</p>
                                    <p>{props.Anwendungsgebiet}</p>
                                </ul>
                            </Box>
                            <Box sx={exportBoxStyling}>
                                <ExportButton/>
                            </Box>
                        </Box>
                        <Box sx={boxStyling}>
                            <Box sx={tableBoxStyling}>
                                <div className='pop__item__list'>
                                    {prof ? <TreeComponent treeItems={prof.profiles2Classes}/> :
                                        <div className='pop__loading'/>}
                                </div>
                            </Box>
                        </Box>
                        <Box sx={boxStyling}>
                            <Detail/>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <button className='pop__button__close' autoFocus onClick={() => {
                        setOpen(false);
                        setChosenItem([]);
                        setChosenOpen(false);
                    }}>
                        Schließen
                    </button>
                </DialogActions>
            </Dialog>
        </>
    );
}
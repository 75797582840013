import React, {useContext, useEffect} from 'react';
import './styling/Cards.css';
import {Client} from "../services/Client.ts";
import CardItem from './CardItem';
import FilterItem from './FilterItem';
import {ProfilesCountContext, ProfilesRawContext, ProfilesFilteredContext, ProfilesLoadBoolContext, ProfilesFilterBoolContext} from "./Store";
import {Grid} from "@mui/material";

function Cards() {

    const [profilesRaw, setProfilesRaw] = useContext(ProfilesRawContext);
    const [profilesFiltered] = useContext(ProfilesFilteredContext);
    const [profilesFilterBool] = useContext(ProfilesFilterBoolContext);
    const [profilesLoadBool, setProfilesLoadBool] = useContext(ProfilesLoadBoolContext);
    const [, setProfilesCount] = useContext(ProfilesCountContext);

    async function getProfiles_Client () {
        try {
            let client = new Client();
            await client.getBimProfiles()
                .then(data => {setProfilesRaw(data); setTimeout(() => {setProfilesLoadBool(true)}, 100)})
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // Einmaliges Ausführen des API-Requests
        getProfiles_Client().then(r => r);
        // eslint-disable-next-line
    }, [])

    let gridData = [];

    // Profil-Zuweisung je nach Filterung (profilesFilterBool)
    // setProfilesCount führt zu roter "Warning"-Meldung.
    // Bei Umsetzung über useEffect wird allerdings in manchen Fällen nicht die gewünschte Anzahl angezeigt.

    if (profilesFilterBool === true) {
        if (profilesFiltered.length > 0) {
            for (let i = 0; i < profilesFiltered.length; i++) {
                gridData.push(profilesFiltered[i]);
                if (profilesFiltered.length === i + 1) {
                    setProfilesCount(profilesFiltered.length)
                }
            }
        } else {
            setProfilesCount(0)
        }
    } else {
        for (let i = 0; i < profilesRaw.length; i++) {
            gridData.push(profilesRaw[i]);
            if (profilesRaw.length === i + 1) {
                setProfilesCount(profilesRaw.length)
            }
        }
    }

    // Kacheldarstellung

    let cyclePhases = [];
    let sprache = [];

    const DisplayGrid = (item) => (

        <Grid container justifyContent="space-evenly">

            {item.map((value) => {

                // Speichern der möglichen LifeCyclePhases

                if (value.lifeCyclePhase !== null) {
                    if (cyclePhases.indexOf(value.lifeCyclePhase) === -1) {
                        cyclePhases.push(value.lifeCyclePhase)
                    }
                }

                // Speichern der möglichen Sprachen

                if (value.sprache !== null) {
                    if (sprache.indexOf(value.sprache) === -1) {
                        sprache.push(value.sprache)
                    }
                }

                // Kachel-Generierung

                return (
                    <CardItem
                        id={value.id}
                        key={value.id}
                        domain={value.domain}
                        identifier={value.identifier}
                        name={value.name}
                        definition={value.definition}
                        language={value.language}
                        areaOfApplication={value.areaOfApplication}
                        license={value.license}
                        logo={value.logo}
                        publishingDate={value.publishingDate}
                        url={value.url}
                        profilTeam={value.profilTeam}
                        servId={value.servId}
                        bauteiltyp={value.bauteiltyp}
                        lifeCyclePhase={value.lifeCyclePhase}
                        isAsia={value.isAsia}
                        sortierungsAnzeige={value.sortierungAnzeige}
                        sprache={value.sprache}
                        herausgeber={value.herausgeber}
                        version={value.version}
                        idXml={value.idXml}
                        guid={value.guid}
                        lastChangeForm={value.lastChangeFrom}
                        languageNavigation={value.languageNavigation}
                        editions2Profiles={value.editions2Profiles}
                        profiles2Classes={value.profiles2Classes}
                        profiles2ClassificationSystems={value.profiles2ClassificationSystems}
                    />
                );
            })}
        </Grid>
    )

    return (
        <div className='cards'>
            <div className='cards__container__out'>
                <FilterItem cycleItems={cyclePhases} spracheItems={sprache}/>
                {profilesLoadBool ? <div className='cards__container__in'>
                    {DisplayGrid(gridData)}
                </div> : <div className='cards__loading'/>}
            </div>
        </div>
    );
}

export default Cards;
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ShortTextIcon from "@mui/icons-material/ShortText";
import React, {useContext} from "react";
import {ChosenItemContext, ChosenOpenContext} from "./Store";

export default function TreeComponent(treeItems) {

    const [, setChosenItem] = useContext(ChosenItemContext);
    const [, setChosenOpen] = useContext(ChosenOpenContext);

    const GetClassItemsFromDataMap = items => {

        return items.treeItems.map(treeItemData => {

            let children_list = undefined

            // in "profiles2Properties" sind Unterelemente enthalten für die die Funktion erneut durchlaufen wird
            if (treeItemData.profiles2Properties && treeItemData.profiles2Properties.length > 0) {
                children_list = GetPropertyItemsFromDataMap(treeItemData.profiles2Properties);
            }

            return (
                <TreeItem
                    key={treeItemData.id + treeItemData.classNavigation.nameDe}
                    nodeId={treeItemData.id}
                    label={treeItemData.classNavigation.nameDe}
                    children={children_list}
                    onClick={() => {
                        //console.log(treeItemData.classNavigation)
                        //setChosenItem(treeItemData.classNavigation)
                        //x = treeItemData.classNavigation
                        setChosenItem(treeItemData.classNavigation)
                        setChosenOpen(true)
                    }}
                />
            );
        });
    };

    const GetPropertyItemsFromDataMap = items => {

        return items.map(treeItemData => {

            return (
                <TreeItem
                    key={treeItemData.id + treeItemData.propertyNavigation.nameDe}
                    nodeId={treeItemData.id}
                    label={treeItemData.propertyNavigation.nameDe}
                    onClick={() => {
                        //console.log(treeItemData.propertyNavigation);
                        //setChosenItem(treeItemData.propertyNavigation)
                        //x = treeItemData.propertyNavigation
                        setChosenItem(treeItemData.propertyNavigation)
                        setChosenOpen(true)
                    }}
                />
            );
        });
    };
    return (
        <>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon/>}
                defaultExpandIcon={<ChevronRightIcon/>}
                defaultEndIcon={<ShortTextIcon/>}
            >
                {GetClassItemsFromDataMap(treeItems)}
            </TreeView>
        </>
    );
}